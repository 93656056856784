<template>
  <div id="app" style="height: 100%">
    <div class="large-screen">
      <header class="index">
        <header-nav active="index"></header-nav>
        <div class="banner swiper-container" id="banner-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide"><img src="images/large/banner1.jpg"></div>
            <div class="swiper-slide"><img src="images/large/banner2.jpg"></div>
            <div class="swiper-slide"><img src="images/large/banner3.jpg"></div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
        <div class="nav-slogan-qr">
          <div class="slogan">
            <h2>城际出行 · 天府行</h2>
            <p>美好旅程 快乐起点</p>
            <p>LET'S DEPART A WONDERFUL</p>
            <p>JOURNEY FROM NOW ON</p>
          </div>
          <!--          <div class="qr">-->
          <!--            <img src="images/large/qr.svg">-->
          <!--            <p>微信扫码关注</p>-->
          <!--          </div>-->
          <el-card class="box-card">
            <div slot="header" class="header">
              <img src="./assets/icon/cj-icon.png">
              <span>我要购票</span>
            </div>
            <el-form ref="form" :model="form" :rules="rules">
              <el-form-item class="input-item" prop="leave">
                <city-select-input
                    type="leave"
                    ref="leave-input"
                    v-model="form.leave"
                    @blur="onBlurLeave"
                    @select="onSelectLeave"
                    @popSelect="onPopSelectLeave">
                </city-select-input>
              </el-form-item>
              <el-form-item class="input-item" prop="arrive">
                <city-select-input
                    type="arrive"
                    ref="arrive-input"
                    v-model="form.arrive"
                    :leave="leave"
                    @blur="onBlurArrive"
                    @select="onSelectArrive"
                    @popSelect="onPopSelectArrive">
                </city-select-input>
              </el-form-item>
              <el-form-item class="input-item" prop="date">
                <div class="date el-input el-input-group el-input-group--prepend">
                  <div class="el-input-group__prepend">出发日期</div>
                  <el-date-picker
                      v-model="form.date"
                      type="date"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions"
                      placeholder="请选择日期">
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item class="button-item">
                <el-button type="primary" class="search-btn" @click="onSubmit('form')">查询</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </div>
      </header>
      <section class="project-container">
        <img src="images/large/project-bg.png">
        <div class="project-content">
          <div class="project-swiper swiper-container" dir="rtl">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="content">
                  <div class="header-img"><img class="cj" src="images/large/cj.png"></div>
                  <p class="title">城际专车 <span>（定制客运）</span></p>
                  <div class="text large-screen-swiper-scroll">
                    <div class="project-detail large-screen-swiper-scroll-container swiper-container">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          以客运企业为开行主体，主要使用7-9座商务车提供“门到门”、“点到点”等多元化的跨城出行服务。为乘客提供高品质的出行体验，助力客运企业转型升级。
                        </div>
                      </div>
                      <!--                      <div class="swiper-scrollbar"></div>-->
                    </div>
                  </div>
                  <div class="btn-more" data-type="cj">了解详情</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="content">
                  <div class="header-img"><img class="tq" src="images/large/tq.png"></div>
                  <p class="title">定制通勤</p>
                  <div class="text large-screen-swiper-scroll">
                    <div class="project-detail large-screen-swiper-scroll-container swiper-container">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          采集用户需求，使用客运大巴，开行一人一座、不换乘的定制通勤线路。解决上班族通勤痛点，提高客运企业车辆的综合使用率。
                        </div>
                      </div>
                      <!--                      <div class="swiper-scrollbar"></div>-->
                    </div>
                  </div>
                  <div class="btn-more" data-type="tq">了解详情</div>
                </div>
              </div>
              <div class="swiper-slide">
                <div class="content">
                  <div class="header-img"><img class="pw" src="images/large/pw.png"></div>
                  <p class="title">汽车票</p>
                  <div class="text large-screen-swiper-scroll">
                    <div class="project-detail large-screen-swiper-scroll-container swiper-container">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          与客运站深度合作，提供汽车票联网售票服务。方便用户在线购票，提升客运站汽车票销量。
                        </div>
                      </div>
                      <!--                      <div class="swiper-scrollbar"></div>-->
                    </div>
                  </div>
                  <div class="btn-more" data-type="pw">了解详情</div>
                </div>
              </div>
            </div>
            <div class="mark"></div>

          </div>
          <div class="project-detail-container">
            <div class="title">
              <img src="images/large/product/cj-line-detail-title-icon.png">
              <p>主营业务</p>
            </div>
            <div class="content">
              <p>天府行专注于城际出行领域，为客运企业转型升级提供出行平台和运营管理咨询服务。主营业务包括：城际出行（定制客运）、定制通勤、汽车票。</p>
            </div>
          </div>
          <div class="btn-group">
            <div class="project-prev"><img src="images/large/icon/product-prev-active.png"></div>
            <div class="project-next"><img src="images/large/icon/product-next-active.png"></div>
          </div>
        </div>
      </section>
      <section class="line-detail-container">
        <p class="line-bg"><img src="images/large/line-bg.png"></p>
        <div class="swiper-container-box"></div>
        <div class="swiper-container" id="line-list-content">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <ul class="line-list-content">
                <li class="active" data-start="cdu" data-end="dyi"><span class="line-name left">成都</span><img
                    src="images/large/line-detail-active-icon.png"><span class="line-name right">大邑</span></li>
                <li data-start="cdu" data-end="czhou"><span class="line-name left">成都</span><span class="circle"><img
                    src="images/large/lineline-detail-icon.png"></span><span class="line-name">崇州</span></li>
                <li data-start="cdu" data-end="myang"><span class="line-name left">成都</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">绵阳</span></li>
                <li data-start="cdu" data-end="sfang"><span class="line-name left">成都</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">什邡</span></li>
                <li data-start="cdu" data-end="jtang"><span class="line-name left">成都</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">金堂</span></li>
                <li data-start="cdu" data-end="pzhou"><span class="line-name left">成都</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">彭州</span></li>
                <li data-start="cdu" data-end="shong"><span class="line-name left">成都</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">射洪</span></li>
                <li data-start="cdu" data-end="bzhong"><span class="line-name left">成都</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">巴中</span></li>
                <li data-start="cdu" data-end="tjiang"><span class="line-name left">成都</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">通江</span></li>
                <li data-start="cdu" data-end="qxian"><span class="line-name left">成都</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">渠县</span></li>
                <li data-start="myang" data-end="stai"><span class="line-name left">绵阳</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">三台</span></li>
                <li data-start="myang" data-end="jyou"><span class="line-name left">绵阳</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">江油</span></li>
                <li data-start="myang" data-end="shong"><span class="line-name left">绵阳</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">射洪</span></li>
                <li data-start="myang" data-end="sning"><span class="line-name left">绵阳</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">遂宁</span></li>
                <li data-start="myang" data-end="ztong"><span class="line-name left">绵阳</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">梓潼</span></li>
                <li data-start="myang" data-end="pxi"><span class="line-name left">绵阳</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">蓬溪</span></li>
                <li data-start="sning" data-end="shong"><span class="line-name left">遂宁</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">射洪</span></li>
                <li data-start="sning" data-end="dying"><span class="line-name left">遂宁</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">大英</span></li>
                <li data-start="sning" data-end="tnan"><span class="line-name left">遂宁</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">潼南</span></li>
                <li data-start="sning" data-end="pxi"><span class="line-name left">遂宁</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">蓬溪</span></li>
                <li data-start="pxi" data-end="nchong"><span class="line-name left">蓬溪</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">南充</span></li>
                <li data-start="mshan" data-end="dling"><span class="line-name left">眉山</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">丹棱</span></li>
                <li data-start="mshan" data-end="qshen"><span class="line-name left">眉山</span><img
                    src="images/large/lineline-detail-icon.png"><span class="line-name right">青神</span></li>
              </ul>
            </div>
          </div>
          <!--          <div class="swiper-scrollbar display-none"></div>-->
        </div>
        <div class="line-map-detail">
          <div class="line-detail">
            <img src="images/large/line-detail-icon.png">
            <div class="content">
              <p class="title">城际专车<span>（定制客运）</span>开行情况</p>
              <p class="text">截止2022年7月，天府行平台开行定制客运线路94条，合作企业80余家，日客流量峰值24000余人次。覆盖川内成都、绵阳、泸州、德阳、广元、遂宁、内江、乐山、资阳、宜宾、南充、达州、雅安、广安、巴中、眉山、甘孜、凉山等18个地市州。</p>
              <!--<div class="btn-more">了解详情</div>-->
            </div>
          </div>
          <div class="line-map">
            <div class="title-top-icon-one display-none"></div>
            <div class="title-top-icon-two display-none"></div>
            <div class="address-item jtang" data-name="jtang">
              <div class="transparent"></div>
              <p class="title">金堂县</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item pzhou" data-name="pzhou">
              <div class="transparent"></div>
              <p class="title">彭州市</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item qshen" data-name="qshen">
              <div class="transparent"></div>
              <p class="title">青神县</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item shong" data-name="shong">
              <div class="transparent"></div>
              <p class="title">射洪市</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item bzhong" data-name="bzhong">
              <div class="transparent"></div>
              <p class="title">巴中</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item tjiang" data-name="tjiang">
              <div class="transparent"></div>
              <p class="title">通江</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item qxian" data-name="qxian">
              <div class="transparent"></div>
              <p class="title">渠县</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item jyou" data-name="jyou">
              <div class="transparent"></div>
              <p class="title">江油</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item ztong" data-name="ztong">
              <div class="transparent"></div>
              <p class="title">梓潼</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item tnan" data-name="tnan">
              <div class="transparent"></div>
              <p class="title">潼南</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item pxi" data-name="pxi">
              <div class="transparent"></div>
              <p class="title">蓬溪</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item nchong" data-name="nchong">
              <div class="transparent"></div>
              <p class="title">南充</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item dyi" data-name="dyi">
              <div class="transparent"></div>
              <p class="title">大邑县</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item czhou" data-name="czhou">
              <div class="transparent"></div>
              <p class="title">崇州市</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item sfang" data-name="sfang">
              <div class="transparent"></div>
              <p class="title">什邡市</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item myang" data-name="myang">
              <div class="transparent"></div>
              <p class="title">绵阳市</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item stai" data-name="stai">
              <div class="transparent"></div>
              <p class="title">三台县</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item sning" data-name="sning">
              <div class="transparent"></div>
              <p class="title">遂宁市</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item dying" data-name="dying">
              <div class="transparent"></div>
              <p class="title">大英县</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item mshan" data-name="mshan">
              <div class="transparent"></div>
              <p class="title">眉山市</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item dling" data-name="dling">
              <div class="transparent"></div>
              <p class="title">丹棱县</p>
              <img src="images/large/address-position.png">
            </div>
            <div class="address-item cdu" data-name="cdu">
              <div class="transparent"></div>
              <p class="title">成都市</p>
              <img src="images/large/address-position.png">
            </div>
            <canvas id="line-map"></canvas>
          </div>
        </div>
      </section>
      <section class="company-culture-container">
        <div class="item">
          <div class="text-icon bottom">
            <div class="center"><img src="images/large/platform-title-icon.png">
              <p>平台研发</p></div>
          </div>
          <div class="content-text platform">
            把握跨城出行领域的趋势，深度挖掘客运企业经营管理需求及用户的出行需求，结合线路车辆组织化、规模化运行的特点，尊重客运企业的独立经营权和调度权，快速研发、迭代出行平台。主要特色功能包括：定点/上门、
            自由选择；灵活验票、扫码上车；车辆定位、掌握行程；一班多车、按需调配；智能调度、高效派车；实时促销、浮动票价；导航接送、省时省心。
          </div>
        </div>
        <div class="item">
          <div class="content-text experience">
            以资深客运专家为核心的运营团队，总结提炼多条城际线路的开行经验，形成标准化的开线流程（市场分析、效益测算、产品设计、运行筹备、营销推广、上线运行、持续改进），为客运企业提供全方位的运营管理咨询服务。
          </div>
          <div class="text-icon top">
            <div class="center"><img src="images/large/experience-title-icon.png">
              <p>运营咨询</p></div>
          </div>
        </div>
        <div class="item">
          <div class="text-icon bottom">
            <div class="center"><img src="images/large/security-title-icon.png">
              <p>品牌形象</p></div>
          </div>
          <div class="content-text security">统一车身、提高辨识度；首创驿站、提高体验感。</div>
        </div>
        <div class="item">
          <div class="content-text qualifications">网约车营运资质、定制客运接入行业监管系统。</div>
          <div class="text-icon top">
            <div class="center"><img src="images/large/qualifications-title-icon.png">
              <p>合法资质</p></div>
          </div>
        </div>
      </section>
      <section class="company-dynamic-list-container">
        <img src="images/large/dynamic.png">
        <ul class="list-content">
          <!-- <li data-id="001">
               <p class="title">天府行首个驿站亮相开业</p>
               <p class="text">9月29日，天府行驿站太平园店正式揭开面纱，这是天府行在成都开设的第一家驿站，距离太平园地铁站D出口仅50米...</p>
               <p class="date">2019-09-29</p>
           </li>
           <li data-id="002">
               <p class="title">天府行和长运旅游分公司举行“天府行定制通勤巴士”开行仪式</p>
               <p class="text">11月18日6时，经过前期车辆定制、线路考察、线上宣传、线下地推等一系列筹备工作，由天府行和富临长运联合打造的...</p>
               <p class="date">2019-11-18</p>
           </li>
           <li data-id="003">
               <p class="title">成都至什邡线路定制客运盛大启动</p>
               <p class="text">9月19日上午，东风风行交车仪式暨成都至什邡定制客运启动仪式在什邡广场隆重举行...</p>
               <p class="date">2019-09-19</p>
           </li>-->
        </ul>
      </section>
      <section class="company-cooperation-container">
        <div class="company-cooperation-content">
          <div class="title">
            <img src="images/large/cooperation-title-icon.png">
            <p>合作伙伴</p>
          </div>
          <div class="content">
            <p>天府行在业务发展过程中，和众多合作伙伴形成了共创共赢的良好合作关系。</p>
          </div>
        </div>
        <div class="company-cooperation-list">
          <img src="images/large/cooperation-list-header.png">
          <div class="cooperation-list-tips">
            <img src="images/large/cooperation-list-footer.png">
            <div class="cooperation-list-tips-content">
              <div class="swiper-container" id="cooperation-list-tips">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="list-content">
                      <div class="swiper-container" id="cooperation-list-scroll">
                        <div class="swiper-wrapper">
                          <div class="swiper-slide">
                            <p>富临运业</p>
                          </div>
                          <div class="swiper-slide">
                            <p>成都公司</p>
                          </div>
                          <div class="swiper-slide">
                            <p>灰狗运业</p>
                          </div>
                          <div class="swiper-slide">
                            <p>亚细亚运业</p>
                          </div>
                          <div class="swiper-slide">
                            <p>东方龙运业</p>
                          </div>
                          <div class="swiper-slide">
                            <p>崇州旅游</p>
                          </div>
                          <div class="swiper-slide">
                            <p>崇州运捷</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div class="swiper-scrollbar display-none"></div>-->
              <div class="tips-content">
                <div class="tips-one">
                  <img src="images/large/customer.png">
                  <p class="title">为乘客</p>
                  <p class="text">提供便捷、安全的城际出行服务</p>
                </div>
                <div class="tips-two">
                  <img src="images/large/company.png">
                  <p class="title">为企业</p>
                  <p class="text">助力转型升级、共创未来</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>

    <div class="small-screen index">
      <header-nav-small active="index"></header-nav-small>
      <div class="swiper-container small-screen-swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide small-screen-swiper-slide first">

            <div class="swiper-container small-screen-swiper-banner-container">
              <div class="slogan">
                <h2>城际出行 · 天府行</h2>
                <p class="chinese">美好旅程 快乐起点</p>
                <p class="english">LET'S DEPART A WONDERFUL<br>
                  JOURNEY FROM NOW ON</p>
                <p class="wechat"><img src="images/small/wechat-icon.png">点击关注公众号</p>
              </div>
              <div class="swiper-wrapper">
                <div class="swiper-slide first">
                  <img class="banner-footer" src="images/small/banner-footer-bg.png">
                </div>
                <div class="swiper-slide second">
                  <img class="banner-footer" src="images/small/banner-footer-bg.png">
                </div>
                <div class="swiper-slide third">
                  <img class="banner-footer" src="images/small/banner-footer-bg.png">
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="next"></div>
            <!--<div class="prev display-none"></div>-->
          </div>
          <div class="swiper-slide small-screen-swiper-slide second project-situation">
            <img class="head-bg" src="images/small/product/project-situation.png">
            <div class="line-detail-container">
              <img src="images/small/product/project-situation-title-icon.png">
              <div class="content">
                <p class="title">主营业务</p>
                <div class="text small-screen-swiper-scroll">
                  <div class="swiper-container line-detail small-screen-swiper-scroll-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        天府行专注于城际出行领域，为客运企业转型升级提供出行平台和运营管理咨询服务。主营业务包括：城际出行（定制客运）、定制通勤、汽车票。
                      </div>
                    </div>
                    <!--                    <div class="swiper-scrollbar"></div>-->
                  </div>
                </div>
              </div>
              <p class="btn-more position"><img src="images/small/icon/return.png"></p>
            </div>

            <!--<div class="btn-more">了解详情</div>-->
            <div class="next"></div>
            <!--<div class="prev display-none"></div>-->
          </div>
          <div class="swiper-slide small-screen-swiper-slide third ">
            <div class="swiper-container small-screen-project-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="img-content">
                    <div style="display: flex; justify-content: center">
                      <img class="cj" src="images/small/product-cj.png">
                    </div>
                    <div class="content">
                      <p class="title">城际专车<span>（定制客运）</span></p>
                      <div class="text small-screen-swiper-scroll">
                        <div class="swiper-container product-detail small-screen-swiper-scroll-container">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide">
                              以客运企业为开行主体，主要使用7-9座商务车提供“门到门”、“点到点”等多元化的跨城出行服务。为乘客提供高品质的出行体验，助力客运企业转型升级。
                            </div>
                          </div>
                          <!--                          <div class="swiper-scrollbar"></div>-->
                        </div>
                      </div>
                      <p class="btn-more position" data-type="cj"><img src="images/small/icon/return.png"></p>
                    </div>
                  </div>
                  <!--<div class="btn-more " data-type="cj">了解详情 </div>-->
                </div>
                <div class="swiper-slide">
                  <div class="img-content">
                    <div style="display: flex; justify-content: center">
                      <img class="tq" src="images/small/product-tq.png">
                    </div>
                    <div class="content">
                      <p class="title">定制通勤</p>
                      <div class="text small-screen-swiper-scroll">
                        <div class="swiper-container product-detail tq small-screen-swiper-scroll-container">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide">
                              采集用户需求，使用客运大巴，开行一人一座、不换乘的定制通勤线路。解决上班族通勤痛点，提高客运企业车辆的综合使用率。
                            </div>
                          </div>
                          <!--                          <div class="swiper-scrollbar"></div>-->
                        </div>
                      </div>
                      <p class="btn-more position" data-type="tq"><img src="images/small/icon/return.png"></p>
                    </div>
                  </div>
                  <!--<div class="btn-more" data-type="tq">了解详情 </div>-->
                </div>
                <div class="swiper-slide">
                  <div class="img-content">
                    <div style="display: flex; justify-content: center">
                      <img class="pw" src="images/small/product-pw.png">
                    </div>
                    <div class="content">
                      <p class="title">汽车票</p>
                      <div class="text small-screen-swiper-scroll">
                        <div class="swiper-container product-detail small-screen-swiper-scroll-container">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide">
                              与客运站深度合作，提供汽车票联网售票服务。方便用户在线购票，提升客运站汽车票销量。
                            </div>
                          </div>
                          <!--                          <div class="swiper-scrollbar"></div>-->
                        </div>
                      </div>
                      <p class="btn-more position" data-type="pw"><img src="images/small/icon/return.png"></p>
                    </div>
                  </div>
                  <!--<div class="btn-more" data-type="pw">了解详情 </div>-->
                </div>
              </div>
            </div>
            <div class="next"></div>
            <!--<div class="prev display-none"></div>-->
          </div>
          <div class="swiper-slide small-screen-swiper-slide second">
            <img class="head-bg" src="images/small/line-detail-bg.png">
            <div class="line-detail-container">
              <img src="images/small/line-detail-title-icon.png">
              <div class="content">
                <p class="title">城际专车<span>（定制客运）</span>开行情况</p>
                <div class="text small-screen-swiper-scroll">
                  <div class="swiper-container line-detail small-screen-swiper-scroll-container">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        截止2022年7月，天府行平台开行定制客运线路94条，合作企业80余家，日客流量峰值24000余人次。覆盖川内成都、绵阳、泸州、德阳、广元、遂宁、内江、乐山、资阳、宜宾、南充、达州、雅安、广安、巴中、眉山、甘孜、凉山等18个地市州。
                      </div>
                    </div>
                    <!--                    <div class="swiper-scrollbar"></div>-->
                  </div>
                </div>
                <!--<p class="btn-more"><img src="images/small/icon/return.png"></p>-->
              </div>
            </div>

            <!--<div class="btn-more">了解详情</div>-->
            <div class="next"></div>
            <!--<div class="prev display-none"></div>-->
          </div>
          <div class="swiper-slide small-screen-swiper-slide fourth">
            <div class="swiper-container small-screen-culture-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="culture-container">
                    <div class="logo-title">
                      <img src="images/small/platform-title-icon.png">
                      <p>平台研发</p>
                    </div>
                    <p class="text">
                      把握跨城出行领域的趋势，深度挖掘客运企业经营管理需求及用户的出行需求，结合线路车辆组织化、规模化运行的特点，尊重客运企业的独立经营权和调度权，快速研发、迭代出行平台。主要特色功能包括：定点/上门、
                      自由选择；灵活验票、扫码上车；车辆定位、掌握行程；一班多车、按需调配；智能调度、高效派车；实时促销、浮动票价；导航接送、省时省心。
                    </p>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="culture-container">
                    <div class="logo-title">
                      <img src="images/small/experience-title-icon.png">
                      <p>运营咨询</p>
                    </div>
                    <p class="text">
                      以资深客运专家为核心的运营团队，总结提炼多条城际线路的开行经验，形成标准化的开线流程（市场分析、效益测算、产品设计、运行筹备、营销推广、上线运行、持续改进），为客运企业提供全方位的运营管理咨询服务。
                    </p>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="culture-container">
                    <div class="logo-title">
                      <img src="images/small/security-title-icon.png">
                      <p>品牌形象</p>
                    </div>
                    <p class="text vertical-center">
                      统一车身、提高辨识度；首创驿站、提高体验感。
                    </p>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="culture-container">
                    <div class="logo-title">
                      <img src="images/small/qualifications-title-icon.png">
                      <p>合法资质</p>
                    </div>
                    <p class="text vertical-center">
                      网约车营运资质、定制客运接入行业监管系统。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="next"></div>
            <!--<div class="prev display-none"></div>-->
          </div>
          <div class="swiper-slide small-screen-swiper-slide fifth">
            <div class="dynamic-container">
              <div style="display: flex; justify-content: center">
                <img src="images/small/dynamic.png">
              </div>
              <div class="swiper-container small-screen-dynamic-swiper">
                <div class="swiper-wrapper">
                  <!--<div class="swiper-slide">
                      <div class="content">
                          <p class="title">天府行首个驿站亮相开业</p>
                          <p class="text">9月29日，天府行驿站太平园店正式揭开面纱，这是天府行在成都开设的第一家驿站，距离太平园地铁站D出口仅50米。</p>
                      </div>
                  </div>
                  <div class="swiper-slide">
                      <div class="content">
                          <p class="title">天府行和长运旅游分公司举行“天府行定制通勤巴士”开行仪式</p>
                          <p class="text">11月18日6时，经过前期车辆定制、线路考察、线上宣传、线下地推等一系列筹备工作，由天府行和富临长运联合打造的...</p>
                      </div>
                  </div>
                  <div class="swiper-slide">
                      <div class="content">
                          <p class="title">成都至什邡线路定制客运盛大启动</p>
                          <p class="text">9月19日上午，东风风行交车仪式暨成都至什邡定制客运启动仪式在什邡广场隆重举行。</p>
                      </div>
                  </div>-->
                </div>
                <div class="pagination dynamic-pagination"></div>
              </div>
            </div>
            <!--<div class="btn-more">查看更多</div>-->
            <div class="next"></div>
            <!--<div class="prev display-none"></div>-->
          </div>
          <div class="swiper-slide small-screen-swiper-slide sixth">
            <div class="contact-us-container">
              <div id="map-container" class="map-container"></div>
              <ul>
                <li><img src="images/small/icon/phone.png"><a href="tel:96579">96579</a></li>
                <li><img src="images/small/icon/email.png"><a href="mailto:bd@tfxing.com">bd@tfxing.com</a></li>
                <li><img src="images/small/icon/address.png">成都市青羊区腾飞大道189号</li>
                <li><img src="images/small/icon/links.png"><a href="http://www.china-yongfeng.com/" target="_blank">永锋集团</a>
                  &nbsp;&nbsp;<a href="http://www.scflyy.cn/" target="_blank">富临运业集团</a></li>
                <li class="weacht-app"><span class="attention"><img src="images/small/icon/wechat.png">关注我们</span> <span
                    class="download">APP下载</span></li>
              </ul>
            </div>
            <footer-small></footer-small>
            <!--<div class="next"></div>-->
            <!--<div class="prev display-none"></div>-->
          </div>
        </div>
      </div>

      <div class="wechat-qr-container display-none">
        <div class="wechat-qr-content">
          <img class="close" src="images/small/icon/menu-close.png">
          <div class="content">
            <img class="qr" src="images/small/icon/wechat-qr.svg">
            <span>扫码关注天府行</span>
          </div>
        </div>
      </div>
    </div>

    <scroll-to-top></scroll-to-top>
  </div>
</template>

<script>
import $ from 'jquery';
import moment from "moment"
import AMapLoader from '@amap/amap-jsapi-loader';
import Swiper from "swiper";
import 'swiper/css/swiper.min.css'
import Base64 from "@/utils/base64";
import {isWeiXin, getAreaInfo} from "@/utils/common";
import HeaderNav from "@/components/header-nav";
import Footer from "@/components/footer";
import HeaderNavSmall from "@/components/header-nav-small";
import FooterSmall from "@/components/footer-small";
import ScrollToTop from "@/components/scroll-to-top";
import CitySelectInput from "@/components/city-select-input"
import Vue from "vue";
import Element from "element-ui";

Vue.use(Element)

function drawLineAnimation(ctx, start, point, end, canvasW, canvasH) {
  const data = {
    start: start,
    point: point,
    end: end
  };

  let percent = 0;
  init();

  function init() {
    percent = 0;
    draw();
  }

  function draw() {
    ctx.clearRect(0, 0, canvasW, canvasH);
    ctx.strokeStyle = createLinearGradient(data.start, data.end, "rgba(255,255,255,.2)", "#fff");
    drawCurvePath(data.start, data.point, data.end, percent, data.department, data.value);
    percent += 0.8;
    if (percent <= 100) {
      requestAnimationFrame(draw);
    } else {
      ctx.clearRect(0, 0, canvasW, canvasH);
      init();
    }
  }

  function drawCurvePath(start, point, end, percent) {
    let x, y;
    ctx.beginPath();
    ctx.moveTo(start[0], start[1]);
    for (let t = 0; t <= percent / 100; t += 0.005) {
      x = quadraticBezier(start[0], point[0], end[0], t);
      y = quadraticBezier(start[1], point[1], end[1], t);
      ctx.lineTo(x, y);
    }
    ctx.stroke();
    createHeadLight(x, y);
  }

  function quadraticBezier(p0, p1, p2, t) {
    const k = 1 - t;
    return k * k * p0 + 2 * (1 - t) * t * p1 + t * t * p2; // 二次贝赛尔曲线方程
  }

  function createLinearGradient(start, end, startColor, endColor) {
    const lineGradient = ctx.createLinearGradient(...start, ...end);
    lineGradient.addColorStop(0, startColor);
    lineGradient.addColorStop(1, endColor);
    return lineGradient;
  }

  function createHeadLight(x, y) {
    ctx.beginPath();
    const radialGradient = ctx.createRadialGradient(x, y, 0, x, y, 10);
    radialGradient.addColorStop(0, "rgba(255,255,255,1)");
    radialGradient.addColorStop(0.2, "rgba(255,255,255,.8)");
    radialGradient.addColorStop(1, "transparent");

    ctx.fillStyle = radialGradient;
    ctx.arc(x, y, 20, 0, 2 * Math.PI, false);
    ctx.fill();
  }

}

function f() {
  if ($('.large-screen:visible').length) {
    new Swiper('.project-swiper', {
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.project-next',
        prevEl: '.project-prev'
      }
    });
    new Swiper('.large-screen-swiper-scroll-container', {
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      mousewheel: true
    });
    new Swiper('#line-list-content', {
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      mousewheel: true,
    });
    new Swiper('#cooperation-list-tips', {
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      mousewheel: true,
    });
    new Swiper('#cooperation-list-scroll', {
      height: 40,
      direction: 'vertical',
      slidesPerView: 'auto',
      autoplay: {
        delay: 1500,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      speed: 500,
      loop: true
    });
    new Swiper('#banner-swiper', {
      autoplay: {
        delay: 3500,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      spaceBetween: 30,
      effect: 'fade',
      pagination: {
        el: '.swiper-pagination',
        bulletClass: 'customize-bullet',
        bulletActiveClass: 'customize-bullet-active',
//                clickableClass : 'my-pagination-clickable',
        clickable: true
      },
      loop: true
    });


    /*$('.line-map-detail>.line-map>.address-item>.transparent').on('click', function () {
     let _this = $(this).parents('.address-item');
     let startName = _this.attr('data-name');
     let StartP = [_this.attr('data-x')*1, _this.attr('data-y')*1];

     $('.line-list-content>li').each(function (index, item) {
     if ($(item).attr('data-start') == startName) {

     }

     });
     });*/

    $.ajax({
      url: "../data/news.json",
      type: "GET",
      dataType: "json",
      success: function (data) {
        // console.log(data);
        if (data.result_state == 0) {
          let result = data.result;
          let dynamicContainer = $('section.company-dynamic-list-container ul');
          let dynamicHtml = '';
          result.forEach((item, index) => {
            if (index < 3) {
              dynamicHtml = '<li>' +
                  '<p class="title">' + item.title + '</p>' +
                  '<p class="text">' + item.content.substr(0, 60) + '...</p>' +
                  '<p class="date">' + item.date.full_date + '</p>' +
                  '</li>';
              dynamicContainer.append($(dynamicHtml).data('id', item.id))
            }
          });
          dynamicContainer.append('<li class="more">更多<img src="images/large/icon/next-active.png"></li>');
          $('section.company-dynamic-list-container ul>li').on('click', function () {
            if ($(this).data('id')) {
              location.href = 'news-content.html?' + Base64.encode('id=' + $(this).data('id'))
            }
          });
          $('.list-content>li.more').on('click', function () {
            location.href = 'news.html'
          });
        }
      },
      error: function () {
      }
    });


    $('.project-container  .btn-more').on('click', function () {
      let type = $(this).attr('data-type');
      location.href = 'product.html?' + Base64.encode('type=' + type);
    });
  } else {
    $('html, body').css({
      'height': '100%',
      'overflow': 'hidden',
    });
    $('body').css({
      'background': '#0A136B url(images/small/stars.png) no-repeat',
      'background-size': 'cover'
    });
    $('.menu-icon').on('click', function () {
      $('.nav-list').removeClass('display-none');
      $('.nav-list>ul').slideToggle('fast');
      $('.nav-logo-close .close, .nav-list').unbind('click').on('click', function () {
        $('.nav-list>ul').slideToggle('fast', function () {
          $('.nav-list').addClass('display-none');
        })
      });
    });

    $('.slogan p.wechat, .weacht-app>span').on('click', function () {
      if ($(this).hasClass('download')) {
        location.href = 'app/customer_download.html';
      }
      if ($(this).hasClass('attention') || $(this).hasClass('wechat')) {
        if (isWeiXin()) {
          location.href = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI5MzQ5ODA0NA==&scene=124#wechat_redirect'
        } else {
          $('.wechat-qr-container').removeClass('display-none');
          $('.wechat-qr-container .close').on('click', function () {
            $('.wechat-qr-container').addClass('display-none');
          });
        }
      }
    });
    new Swiper('.small-screen-swiper-banner-container', {
      loop: true,
      autoplay: {
        delay: 3500,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    });

    const swiperSlide = document.querySelectorAll(".small-screen-swiper-slide");
    const smallScreenSwiper = new Swiper('.small-screen-swiper-container', {
      direction: 'vertical',
      initialSlide: 0,
      speed: 800,
      followFinger: false,
      touchRatio: 0.1,
      resistanceRatio: 0,
      on: {
        slideChangeTransitionStart: function () {
          let activeIndex = this.activeIndex;
          let previousIndex = this.previousIndex;
          swiperSlide[previousIndex].style.zIndex = -9999;
          for (let i = 0; i < swiperSlide.length; i++) {
            swiperSlide[i].classList.add("swiper-no-swiping")
          }
          setTimeout(function () {
            for (let i = 0; i < swiperSlide.length; i++) {
              if (i != 1) {
                swiperSlide[i].classList.remove("swiper-no-swiping")
              }
            }
          }, 1000);
          if (activeIndex > previousIndex) {
            swiperSlide[previousIndex].style.transform = "translateY(" + smallScreenSwiper.height + "px) scale(0.8)";
            swiperSlide[previousIndex].style.webkitFilter = "brightness(0.5)";
          } else {
            swiperSlide[previousIndex].style.transform = "translateY(" + -smallScreenSwiper.height + "px) scale(0.8)";
            swiperSlide[previousIndex].style.webkitFilter = "brightness(0.5)"
          }

          if (activeIndex === 0) {
            /* $('.next').removeClass('display-none');
             $('.prev').addClass('display-none');*/
          }
          if (activeIndex === 1) {
            swiperSlide[1].classList.remove("swiper-no-swiping")
          }
          if (activeIndex === 2) {
            new Swiper('.small-screen-project-swiper', {
//                                initialSlide : 0,
              loop: true,
              autoplay: {
                delay: 3500,
                stopOnLastSlide: false,
                disableOnInteraction: false
              },
              slidesPerView: 'auto',
            });

            $('.small-screen-project-swiper .btn-more').on('click', function () {
              let type = $(this).attr('data-type');
              location.href = 'product.html?' + Base64.encode('type=' + type);
            });

          }
          if (activeIndex === 4) {
            /*$('.next').addClass('display-none');
            $('.prev').removeClass('display-none');*/
            new Swiper('.small-screen-culture-swiper', {
//                                initialSlide : 0,
              loop: true,
              autoplay: {
                delay: 3500,
                stopOnLastSlide: false,
                disableOnInteraction: false
              },
              slidesPerView: 'auto',
            });
          }
        },
        slideChangeTransitionEnd: function () {
          let previousIndex = this.previousIndex;
          swiperSlide[previousIndex].style.transform = "translateY(0px) scale(1)";
          swiperSlide[previousIndex].style.zIndex = 0;
          swiperSlide[previousIndex].style.webkitFilter = "brightness(1)"
        }
      },
      navigation: {
        nextEl: '.next',
        prevEl: '.prev'
      },
      noSwiping: true
    });

    $('.project-situation .btn-more').on('click', function () {
      location.href = 'product.html';
    });

    new Swiper('.small-screen-swiper-scroll-container', {
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      mousewheel: true
    });

    $.ajax({
      url: "../data/news.json",
      type: "GET",
      dataType: "json",
      success: function (data) {
        // console.log(data);
        if (data.result_state == 0) {
          let result = data.result;
          let dynamicContainer = $('.small-screen-dynamic-swiper .swiper-wrapper');
          let dynamicHtml = '';
          result.forEach((item, index) => {
            if (index < 3) {
              dynamicHtml = '<div class="swiper-slide">' +
                  '<div class="content">' +
                  '<p class="title">' + item.title + '</p>' +
                  '<p class="text">' + item.content.substr(0, 60) + '...</p>' +
                  '</div>' +
                  '</div>';
              dynamicContainer.append($(dynamicHtml).data('id', item.id))
            }
          });
          new Swiper('.small-screen-dynamic-swiper', {
            loop: true,
            autoplay: {
              delay: 3500,
              stopOnLastSlide: false,
              disableOnInteraction: false
            },
            pagination: {
              el: '.dynamic-pagination',
              clickable: true
            },
            paginationClickable: true,
          });

          $('.small-screen-dynamic-swiper .swiper-wrapper>.swiper-slide').on('click', function () {
            if ($(this).data('id')) {
              location.href = 'news-content.html?' + Base64.encode('id=' + $(this).data('id'))
            }
          });
        }
      },
      error: function () {
      }
    });

    AMapLoader.load({
      "key": process.env.VUE_APP_AMAP_KEY,
    }).then((AMap) => {
      const map = new AMap.Map("map-container", {
        zoomEnable: false,
        dragEnable: false,
        resizeEnable: true,
        center: [103.953615, 30.685180],
        zoom: 13
      });
      const marker = new AMap.Marker({
        icon: '../../../images/small/icon/address-icon.png',
        position: [103.953615, 30.685180],
        offset: new AMap.Pixel(-16, -20)
      });
      marker.setMap(map);
    }).catch(e => {
      console.log(e);
    })
  }
}

export default {
  name: 'index',
  data() {
    return {
      leave: null,
      arrive: null,
      form: {
        leave: null,
        arrive: null,
        date: moment().format('YYYY-MM-DD')
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < moment().subtract(1, 'days');
        },
      },
      rules: {
        leave: [
          {required: true, message: '请输入出发城市', trigger: 'change'}
        ],
        arrive: [
          {required: true, message: '请输入到达城市', trigger: 'change'}
        ],
        date: [
          {required: true, message: '请选择出发日期', trigger: 'change'}
        ],
      },
      triggerLeaveEvent: true,
    }
  },
  components: {
    CitySelectInput,
    HeaderNav,
    Footer,
    HeaderNavSmall,
    FooterSmall,
    ScrollToTop,
  },
  watch: {
    leave(newVal, oldVal) {
      if (newVal != oldVal && !this.triggerLeaveEvent) {
        this.arrive = null;
        this.form.arrive = null
      }
      this.triggerLeaveEvent = false
    }
  },
  methods: {
    onSubmit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          localStorage.setItem('classes-search-history', JSON.stringify({leave: this.leave, arrive: this.arrive}))
          sessionStorage.setItem('classes-search-history', JSON.stringify({date: this.form.date}))
          location.href = './buy.html'
        } else {
          return false;
        }
      });
    },
    onBlurLeave() {
      if (this.leave) this.form.leave = this.leave.area_name
    },
    onBlurArrive() {
      if (this.arrive) this.form.arrive = this.arrive.area_name
    },
    onSelectLeave(item) {
      this.leave = item
    },
    onSelectArrive(item) {
      this.arrive = item
    },
    onPopSelectLeave(item) {
      this.leave = item
      this.form.leave = item.area_name
    },
    onPopSelectArrive(item) {
      this.arrive = item
      this.form.arrive = item.area_name
    }
  },
  created() {
    getAreaInfo()
  },
  mounted() {
    f.call()
    setTimeout(() => {
      if ($('.large-screen:visible').length) {
        let lineMapContainer = $('section.line-detail-container>.line-map-detail>.line-map');
        let canvasW = lineMapContainer.width();
        let canvasH = lineMapContainer.height();
        $('#line-map').attr('width', canvasW).attr('height', canvasH);
        let ctx = $("#line-map")[0].getContext("2d");
        $('.line-list-content>li').unbind('click').on('click', function () {
          let imgActiveSrc = 'images/large/line-detail-active-icon.png', _this = $(this);
          let imgSrc = 'images/large/lineline-detail-icon.png';
          if (!_this.hasClass('active')) {
            _this.addClass('active').siblings('li').removeClass('active');
            _this.find('img').attr('src', imgActiveSrc);
            _this.siblings('li').find('img').attr('src', imgSrc);
          }

          let startName = _this.attr('data-start');
          let endName = _this.attr('data-end');
          let startP = [];
          let endP = [];
          $('.line-map-detail>.line-map>.address-item').removeClass('active').each(function (index, item) {
            let left = $(item).position().left;
            let top = $(item).position().top;
            if ($(item).hasClass(startName)) {
              startP.push(left + 23);
              startP.push(top - 9);
              $(item).addClass('active');
            }
            if ($(item).hasClass(endName)) {
              endP.push(left + 23);
              endP.push(top - 9);
              $(item).addClass('active');
            }
          });
          drawLineAnimation(ctx, startP, [Math.floor((startP[0] + endP[0]) / 3), Math.floor((startP[1] + endP[1]) / 3) + 10], endP, canvasW, canvasH);

        });
        $('.line-list-content>li:first-child').trigger("click");
      }
    }, 1000)

    const classesSearchHistory = JSON.parse(localStorage.getItem('classes-search-history'))
    if (classesSearchHistory) {
      this.leave = classesSearchHistory.leave
      this.arrive = classesSearchHistory.arrive
      this.form.leave = this.leave.area_name
      this.form.arrive = this.arrive.area_name
    }
    const dateHistory = JSON.parse(sessionStorage.getItem('classes-search-history'))
    if (dateHistory) this.form.date = dateHistory.date
  },
}
</script>

<style lang="scss">
.box-card {
  margin: .5rem 1rem;
  max-width: 6rem;
  color: #ffffff;
  background-color: rgba(0, 8, 88, .15);
  border-color: rgba(0, 8, 88, .3);

  .el-card__header {
    padding: 10px 15px;
    border-bottom-color: rgba(0, 8, 88, .3);
    background-color: transparent;
    font-weight: normal;

    .header {
      color: rgb(255, 255, 255, .9);
      font-size: smaller;
      //font-weight: 800;
      display: flex;
      align-items: center;

      img {
        width: .4rem;
        margin: .05rem .1rem 0 0;
      }
    }
  }

  .input-item {
    .el-input-group__prepend {
      color: #ffffff;
    }

    .date {
      .el-input-group__prepend {
        background-color: $--color-info;
        border-color: $--color-info;
      }

      .el-date-editor {
        width: inherit;

        input {
          padding-left: 15px;
          width: inherit;
        }

        .el-input__prefix {
          display: none !important;
        }
      }
    }
  }

  .button-item {
    margin-bottom: 0;

    .el-form-item__content {
      display: flex;

      .search-btn {
        margin: 0 15px;
        flex-grow: 1;
      }
    }
  }

}
</style>
